import React from "react";
import styled from "styled-components";

import Video from "../general/Video";
import NewsletterForm from "../forms/NewsletterForm";

const NewsletterStyles = styled.div`
  padding-top: 7%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;

  .newsletter-title {
    h3 {
      font-size: 20px !important;
    }
  }

  @media (max-width: 1280px) {
    padding-top: 7%;
  }

  @media (max-width: 1200px) {
    padding-top: 5%;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @media (max-width: 1000px) {
    padding-top: 8%;
  }

  fieldset {
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;

    input {
      padding: 1rem 0.6rem;
    }
  }
`;

const MainNewsletter = ({ video }) => {
  return (
    <NewsletterStyles className="bkg-color">
      <div className="signup-video">
        <Video data={video} />
      </div>
      <NewsletterForm />
    </NewsletterStyles>
  );
};

export default MainNewsletter;
