import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";

import Linka from "../components/utility/Linka";
import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import MainNewsletter from "../components/forms/MainNewsletter";
import SocialMediaSection from "../components/general/SocialMediaSection";

const OurStoryStyles = styled.div`
  .content {
    padding-left: 10%;
    padding-right: 10%;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    margin-bottom: 3rem;
    display: grid;

    h1 {
      text-align: center;
    }

    h2 {
      margin: 0;
    }

    img {
      justify-self: center;
      margin-bottom: 2rem;
    }

    .signup {
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .bkg-color {
    background-color: var(--white);
    padding-top: 0;
  }

  .files {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    img {
      max-width: 100%;
      width: 200px;
    }
  }
`;

export function Head({ data: { page }, location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{
        title: "Band EPK",
        robotsContent: "no index, follow",
      }}
      location={location}
    />
  );
}

export default function OurStoryPage({
  data: {
    signupVideo,
    about,
    files,
    channels: { nodes: channels },
  },
  location,
}) {
  return (
    <OurStoryStyles>
      <div className="content">
        <h1>Band EPK</h1>
      </div>
      <MainNewsletter video={signupVideo} />
      <div className="content">
        <h2>Band Material</h2>
        <p>Click image to download file.</p>
        <div className="files">
          {files.edges.map((file, i) => (
            <div key={i}>
              <p className="file-title">{file.node.title}</p>

              <Linka to={file.node.file.asset.url} target="_blank">
                <div className="file-image">
                  {file.node.image && (
                    <GatsbyImage
                      image={file.node.image.image.asset.gatsbyImageData}
                      alt={file.node.image.alt}
                      style={{ border: "1px solid black" }}
                    />
                  )}
                </div>
              </Linka>

              <p className="file-info">{file.node.info}</p>
            </div>
          ))}
        </div>
      </div>

      <SocialMediaSection channels={channels} />
    </OurStoryStyles>
  );
}

export const query = graphql`
  query {
    signupVideo: sanityVideo(title: { eq: "Newsletter Signup" }) {
      allow
      frameborder
      fullscreen
      height
      loop
      title
      url
      width
    }

    about: sanitySection(title: { eq: "About Family I Love" }) {
      textEditor {
        _key
        _type
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    files: allSanityEpk {
      edges {
        node {
          title
          file {
            asset {
              url
            }
          }
          info
          image {
            alt
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }

    channels: allSanityChannel {
      nodes {
        title
        url
        image {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
