import React from "react";
import styled from "styled-components";

const VideoStyles = styled.div`
  video {
    clip-path: inset(18px 0);

    @media (min-width: 1400px) {
      clip-path: inset(24px 0);
    }

    @media (max-width: 1000px) {
      clip-path: inset(15px 0);
    }
  }
`;

const isExternalLink = (text) => {
  if (text.includes("http://") || text.includes("https://")) {
    return true;
  } else {
    return false;
  }
};

const Video = ({ data }) => {
  return (
    <VideoStyles>
      <div className="video">
        {isExternalLink(data.url) ? (
          <iframe
            src={data.url}
            controls="0"
            autoplay="1"
            title={data.title}
            width={data.width}
            height={data.height}
            frameBorder={data.frameborder ? "1" : "0"}
            loop={data.loop ? "1" : "0"}
            allow={data.allow}
            allowFullScreen={data.fullscreen}
          ></iframe>
        ) : (
          <video autoPlay loop muted width="100%">
            {["mp4", "webm"].map((vidType) => (
              <source
                key={vidType}
                src={`https://drive.google.com/uc?export=download&id=${data.url}`}
                type={`video/${vidType}`}
              />
            ))}
          </video>
        )}
      </div>
    </VideoStyles>
  );
};

export default Video;
